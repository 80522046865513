/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 60px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 60px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

body.base-simple main {
  min-height: 100vh;
}

[role="main"] {
  padding-top: 58px; /* Space for fixed navbar */
  min-height: 100vh;
}

.nav-underline a.nav-link {
  color: rgba(255, 255, 255, 1);
}

.nav-underline a.nav-link {
  color: rgba(255, 255, 255, 0.6);
}

.nav-underline .nav-link {
  padding: 0.5rem;
}

.nav-underline a.nav-link:hover,
.nav-underline .nav-link.active {
  color: rgba(255, 255, 255, 1);
}

.nav-underline .nav-link .model-title {
  text-decoration: none;
}

.nav-underline .dropdown-item {
  color: #212529;
}

.nav-underline .dropdown-item.active,
.nav-underline .dropdown-item:active {
  color: #fff;
}

/* Breadcrumbs */
ol.breadcrumb {
  font-size: 0.875rem;
}

td.view-transactions-link,
th.view-transactions-header {
  display: none;
}

body.staff td.view-transactions-link,
body.staff th.view-transactions-header,
body.customer td.view-transactions-link,
body.customer th.view-transactions-header {
  display: table-cell;
}

body.full-screen-body [role="main"] {
  padding-bottom: 0;
}

.full-screen-app {
  height: calc(100vh - 58px);
  position: relative;
}

.modal-dialog {
  margin-top: 5rem;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: auto;
}

.full-width-datepicker .react-datepicker-wrapper,
.full-width-datepicker .react-datepicker__input-container {
  display: block;
}

/* API List View */
.api-list-view .expand {
  width: 99%;
}

.api-list-view td.shrink {
  white-space: nowrap;
}

.orderable {
  cursor: pointer;
}

.api-list-view.use-flex .list-view-row {
  flex-wrap: wrap;
  width: 100%;
}

.api-list-view.use-flex .list-view-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.75rem;
  width: 200px;
}

.api-list-view.use-flex .list-view-header-row {
  font-weight: bold;
}

.api-list-view.use-flex .list-view-header-row .column-header-toolbar {
  font-weight: normal;
}

.api-list-view.use-flex .list-view-header-row .text-right .d-flex {
  justify-content: flex-end;
}

.api-list-view.use-flex .list-view-header-row .text-center .d-flex {
  justify-content: center;
}

.api-list-view.use-flex .list-view-header-row .list-view-cell {
  white-space: normal;
}

.api-list-view.use-flex .list-view-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}

.api-list-view.use-flex .list-view-cell.number {
  width: 120px;
}

/* Free trial badge */
#ic-navbar .trial-badge {
  font-size: 0.8em;
}

.ic-cleanup-toolbar {
  background-color: rgb(255, 255, 255);
  top: 56px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: -12px;
}

.ic-cleanup-toolbar .select-btn {
  background-color: rgb(255, 255, 255);
}

.ic-cleanup-toolbar .select-btn:hover {
  background-color: rgb(52, 58, 64);
  color: rgb(255, 255, 255);
}

.filter-click-parent {
  position: relative;
}

.filter-click-parent .filter-click {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 8px;
}

.filter-click-parent:hover .filter-click {
  display: inline;
}

@media (max-width: 1250px) {
  .filter-on-hover {
    padding-right: 2rem !important;
  }
}

#csv-import-detail .error-traceback-list {
  color: white;
}

#csv-import-detail .error-traceback {
  font-family: Monaco;
}

.knowledge-base-badge {
  background-color: rgb(39, 49, 219);
}

.import-cleanup-table .item-match-group.unselected:hover {
  background: rgba(0, 0, 0, 0.075);
}

.import-cleanup-table .item-match-group {
  cursor: pointer;
}

.import-cleanup-table .with-cleanup-action.track .border {
  border: 2px solid rgb(253, 191, 111) !important;
}

.import-cleanup-table .with-cleanup-action.track .border-left {
  border-left: 2px solid rgb(253, 191, 111) !important;
}

.import-cleanup-table .with-cleanup-action.track .border-right {
  border-right: 2px solid rgb(253, 191, 111) !important;
}

.import-cleanup-table .with-cleanup-action.release .match-border {
  border-top: 2px solid rgb(190, 186, 218) !important;
  border-bottom: 2px solid rgb(190, 186, 218) !important;
}

.import-cleanup-table .with-cleanup-action.release .border-left {
  border-left: 2px solid rgb(190, 186, 218) !important;
}

.import-cleanup-table .with-cleanup-action.release .border-right {
  border-right: 2px solid rgb(190, 186, 218) !important;
}

.import-cleanup-table .with-cleanup-action.track .match-border {
  border-top: 2px solid rgb(253, 191, 111) !important;
  border-bottom: 2px solid rgb(253, 191, 111) !important;
}

.import-cleanup-table
  .item-match-group.table-primary.with-cleanup-action
  .match-border {
  border-top: 2px solid rgb(0, 123, 255) !important;
  border-bottom: 2px solid rgb(0, 123, 255) !important;
}

.import-cleanup-table
  .item-match-group.table-primary.with-cleanup-action
  .border-left {
  border-left: 2px solid rgb(0, 123, 255) !important;
}

.import-cleanup-table
  .item-match-group.table-primary.with-cleanup-action
  .border-right {
  border-right: 2px solid rgb(0, 123, 255) !important;
}

.import-cleanup-table .padding-row td {
  padding: 6px;
}

/* Transaction list */
.btn-group > .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Print media */
@media print {
  #beacon-container {
    display: none;
  }
}
